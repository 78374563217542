import { useEffect } from 'react';
import Checkbox from '@/Components/Checkbox';
import GuestLayout from '@/Layouts/GuestLayout';
import InputError from '@/Components/InputError';
import InputLabel from '@/Components/InputLabel';
import PrimaryButton from '@/Components/PrimaryButton';
import TextInput from '@/Components/TextInput';
import { Head, Link, useForm } from '@inertiajs/react';
import { useInView } from 'react-intersection-observer';


import pes from '../../../../storage/app/public/logreg.jpg';
import logo from '../../../../storage/app/public/logo-dark.png';

export default function Login({ status, canResetPassword }) {
    const sectionRef = useInView({ triggerOnce: true, threshold: 0.3 });

    const { data, setData, post, processing, errors, reset } = useForm({
        email: '',
        password: '',
        remember: false,
    });

    useEffect(() => {
        return () => {
            reset('password');
        };
    }, []);

    const submit = (e) => {
        e.preventDefault();

        post(route('login'));
    };

    return (
        <>
            <Head title="Prihlásenie" />

            {status && <div className="mb-4 font-medium text-sm text-green-600">{status}</div>}

            <section ref={sectionRef.ref} className={`fade-in-section${sectionRef.inView ? 'is-visible' : ''}`}>
                <div className='bg-white h-screen md:h-auto'>
                    <img src={pes} alt="logreg" className='absolute z-10 h-screen p-6 object-cover block md:hidden opacity-40' style={{ borderRadius: '50px' }} />
                    <div className='grid md:grid-cols-2 z-20 relative'>
                        <div className='flex flex-wrap items-center place-content-center pt-10 md:pt-0 justify-center'>
                            <div className='flex justify-center w-full'>
                                <a href="/"><img src={logo} className='' alt="logo" /></a>
                            </div>

                            <div className='w-full'>
                                <div className="mx-auto max-w-screen-xl px-4 py-16 sm:px-6 lg:px-8">
                                    <div className="mx-auto max-w-lg text-center">
                                        <h1 className="text-2xl font-bold sm:text-3xl text-[#26303D]">Prihlásenie</h1>


                                    </div>

                                    <form onSubmit={submit} className="mx-auto mb-0 mt-8 max-w-md space-y-4">

                                        <div className=''>
                                            <label htmlFor="email" className="sr-only">Email</label>

                                            <div className="relative">
                                                <input
                                                    type="email"
                                                    id="email"
                                                    name="email"
                                                    className="w-full rounded-3xl border-[#EEF0F3] p-4 ps-12 text-sm shadow-sm text-[#26303D]"
                                                    value={data.email}
                                                    onChange={(e) => setData('email', e.target.value)}
                                                    placeholder="email@email.com"
                                                />

                                                <span className="absolute inset-y-0 start-1 grid place-content-center px-4">
                                                    <i className="fa-regular fa-envelope"></i>
                                                </span>

                                            </div>

                                        </div>

                                        <InputError message={errors.email} className="mt-2 px-4" />

                                        <div className='py-2'>
                                            <label htmlFor="password" className="sr-only">Password</label>

                                            <div className="relative">
                                                <input
                                                    type="password"
                                                    id="password"
                                                    name="password"
                                                    value={data.password}
                                                    onChange={(e) => setData('password', e.target.value)}
                                                    className="w-full rounded-3xl border-[#EEF0F3] p-4 ps-12 text-sm shadow-sm text-[#26303D]"
                                                    placeholder="Zadajte heslo"
                                                />

                                                <span className="absolute inset-y-0 start-1 grid place-content-center px-4">
                                                    <i className="fa-solid fa-lock"></i>
                                                </span>

                                            </div>
                                        </div>

                                        <InputError message={errors.password} className="mt-2 px-4" />

                                        <label htmlFor="Option1" className="flex cursor-pointer items-start gap-2">
                                            <div className="flex items-center">
                                                &#8203;
                                                <input
                                                    name="remember"
                                                    checked={data.remember}
                                                    onChange={(e) => setData('remember', e.target.checked)}
                                                    type="checkbox" className="size-4 rounded border-gray-300" id="Option1" />
                                            </div>

                                            <div>
                                                <strong className="font-bold text-[#26303D] text-sm"> Zostať prihlásený  </strong>
                                            </div>
                                        </label>

                                        <div className='flex'>
                                            <button className="px-12 py-3 text-white bg-[#BE1622] hover:bg-[#BE1622]/80 transition w-full text-center rounded-3xl" disabled={processing}>Prihlásiť sa</button>
                                        </div>

                                        <div className="flex flex-col flex-wrap items-center justify-center">
                                            {canResetPassword && (
                                                <p className="text-sm text-[#667085] text-center">

                                                    <a className="underline text-[#BE1622]" href={route('password.request')}>Zabudli ste heslo?</a>
                                                </p>
                                            )}
                                            <p className="text-sm text-[#667085] text-center">

                                                Ešte nemáte účet? <a className="underline text-[#BE1622]" href="/register">Registrujte sa</a>
                                            </p>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className=''>
                            <img src={pes} alt="logreg" className='hidden md:block md:h-screen p-6 object-cover relative' style={{ borderRadius: '50px' }} />
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}
